.__inner-containerless {
	@apply relative p-4 mx-auto;
}

#section-facts {
}

.facts-wrapper {
}

._fact {
	@apply bg-white border-black border-4 p-4 font-semibold transition-all duration-75 text-xl md:text-mp-lg font-sans;
	box-shadow: 5px 5px var(--tw-shadow-color);
	&:hover {
		box-shadow: 10px 10px var(--tw-shadow-color);
	}
}
