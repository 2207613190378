@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

html,
body {
	max-width: 100%;
	width: 100%;
	overflow-x: hidden;
	@apply bg-neutral-500;
}

@import "./toggle.scss";
@import "./scrolling-ticker.scss";
@import "./section-facts.scss";
@import "./bg-waves.scss";
@import "swiper/css";
@import "./downloads.css";

.text-h1 {
	@apply font-black uppercase font-sans-extrawide text-mp-2xl md:text-mp-3xl;
}

.text-h2 {
	@apply uppercase font-sans-extrawide font-black text-mp-3xl lg:text-mp-8.5xl;
}

// .text-h3 {
// 	@apply text-2xl md:text-3xl;`
// }

// .text-h4 {
// 	@apply text-2xl md:text-2xl font-bold;
// }

p {
	@apply text-xl;
}

section {
	@apply relative w-full h-auto z-10;
}
img {
	@apply pointer-events-none;
}
svg {
	@apply max-w-full h-auto p-0 m-0 block;
}

.svg-wrapper svg {
	@apply w-full h-auto p-0 m-0 block;
}

.button {
	@apply border-4 border-blue text-blue;
	@apply font-bold inline-block leading-4 pt-3 pb-2 px-6 md:px-4;
	// border-radius: 2rem;
	@apply rounded-lg;
	transition: 0.2s;
	&:hover {
		background-color: white;
	}
}

.button-white {
	@apply border-4 border-white text-white;
	@apply font-bold inline-block leading-4 pt-3 pb-2 px-6 md:px-4;
	@apply rounded-lg;
	transition: 0.2s;
	&:hover {
	}
}

.__inner {
	@apply relative container p-4 mx-auto max-w-screen-sm lg:max-w-screen-lg;
	@apply xl:max-w-screen-xl;
	// border: 1px dashed lime;
}

._sticker {
	@apply absolute w-32 md:w-40 h-auto lg:w-48 z-40;
}

#section-hero {
	background: linear-gradient(180deg, #83c5e7 0%, #ffffff 100%);
	// padding-bottom: 100px;
}
