// $moonBlue: rgba(10, 77, 147, 1);
// $sunRed: rgba(238, 40, 47, 1);

.sunbadToggle {
	position: relative;
	width: 100px;
	height: 50px;
	cursor: pointer;
	z-index: 99;
	margin: auto;
}

.sunbadToggle__checkbox {
	position: absolute;
	display: none;
	cursor: pointer;

	&:checked ~ .sunbadToggle__slider {
		// background-color: $sunRed;
		@apply bg-red;
		.sunbadToggle__circle {
			left: 5px;
		}
	}
}

.sunbadToggle__slider {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid black;
	border-radius: 50px;
	// background-color: $moonBlue;
	@apply bg-blue;
	transition: 0.3s;
	cursor: pointer;
}

.sunbadToggle__circle {
	position: absolute;
	top: 5px;
	left: 55px;
	transition: 0.3s;
}
.sunbadToggle__sun {
	position: absolute;
	top: 8px;
	right: 6px;
}
.sunbadToggle__moon {
	position: absolute;
	top: 8px;
	left: 6px;
}

#section-final .sunbadToggle {
	@apply md:ml-0;
}
