#scrolling-ticker {
	@apply bg-red text-yellow w-full h-12 overflow-hidden;

	.ticker-wrapper {
		@apply absolute flex flex-row flex-nowrap;
	}

	ul.ticker-list {
		margin: 0;
		padding: 0;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		@apply flex flex-row flex-nowrap;

		li {
			list-style: none;
			margin: 0 3em 0 0;
			padding: 0;
			display: inline-block;
			line-height: 2.3em;
			vertical-align: center;
			font-size: 1.5em;
		}
	}
}
