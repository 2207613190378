.swiper-button-disabled {
	@apply opacity-0 pointer-events-none;
}

.swiper-button-next,
.swiper-button-prev {
	@apply hidden md:block transition-all duration-75;
	top: calc(50% - 1.5rem);
	max-width: 80px;
}

@media screen and (min-width: screen("md")) {
	.swiper-button-next,
	.swiper-button-prev {
		max-width: auto;
	}
}

.swiper-button-next:hover {
	@apply translate-x-2;
}
.swiper-button-prev:hover {
	@apply -translate-x-2;
}
