.wavesBG-container,
#wavesBG-container {
	padding-top: 50px;
	margin-top: -50px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: calc(100% + 50px);
	z-index: 1;
}

.svg-wavesBG,
#svg-wavesBG {
	overflow: visible;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
